body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "DIN W01 Regular";
  src: url("fonts/5591097/6ceed230-b2b3-4422-b048-4aa11687430a.woff2")
      format("woff2"),
    url("fonts/5591097/80b0143f-6f0d-4dce-aafd-f3c81b85d177.woff")
      format("woff");
}

@font-face {
  font-family: "DIN W01 Bold";
  src: url("fonts/5591154/55fa1cf8-baf8-4bf8-9718-b096b1dd231f.woff2")
      format("woff2"),
    url("fonts/5591154/4cadc2f9-fb32-4b99-b1f2-9ce6d68afbd5.woff")
      format("woff");
}
